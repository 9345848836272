.gif {
    background: repeat center center fixed;
    background-size: contain;
    height: 100%;
    overflow: hidden;

    &__attribution {
        position: absolute;
        right: 20px;
        bottom: 20px;
        width: 150px;
    }
}
