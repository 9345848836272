body {
    font-family: 'Noto Sans', sans-serif;
}

h2 {
    text-align: center;
}

td {
    padding: 5px 10px;
}

:global(#fit-motivator) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
